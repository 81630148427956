// extracted by mini-css-extract-plugin
export var carouselNavigation = "premisePage-module--carouselNavigation--FgIZ9";
export var center = "premisePage-module--center--ZfFSs";
export var flexList = "premisePage-module--flexList--3OAMB";
export var hashbutton = "premisePage-module--hashbutton--XVG80";
export var hashbuttoncontainer = "premisePage-module--hashbuttoncontainer--G-R97";
export var infoContainer = "premisePage-module--infoContainer--Tgx1W";
export var ingressiContainer = "premisePage-module--ingressiContainer--WOPy2";
export var kohdeEsittely = "premisePage-module--kohdeEsittely--uzAdv";
export var left = "premisePage-module--left--jeEWy";
export var mainImageContainer = "premisePage-module--mainImageContainer--7V5Iq";
export var paragraphHolder = "premisePage-module--paragraphHolder--+pYAK";
export var premiseliftcontainer = "premisePage-module--premiseliftcontainer--R-vb1";
export var premiselifttextarea = "premisePage-module--premiselifttextarea--9cNxp";
export var premiselifttextareacontainer = "premisePage-module--premiselifttextareacontainer--yHSQT";
export var right = "premisePage-module--right--QfTfY";
export var sisustuskierrosnosto = "premisePage-module--sisustuskierrosnosto--t4XmZ";
export var tarinatContainer = "premisePage-module--tarinatContainer--KpSba";
export var titleContainer = "premisePage-module--titleContainer--QKSRD";