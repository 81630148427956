// extracted by mini-css-extract-plugin
export var MuiDialogContainer = "carousel-module--MuiDialog-container--IvcWA";
export var carouselCloseButton = "carousel-module--carouselCloseButton--xBgbD";
export var carouselDialog = "carousel-module--carouselDialog--GF1ci";
export var carouselDialogContainer = "carousel-module--carouselDialogContainer--bk6So";
export var carouselDialogImage = "carousel-module--carouselDialogImage--Ss6Un";
export var carouselOverlay = "carousel-module--carouselOverlay--HsAr6";
export var carouselSlide = "carousel-module--carouselSlide--LDQ7H";
export var carouselSlideButton = "carousel-module--carouselSlideButton--dlGfI";
export var center = "carousel-module--center--l2NGM";
export var gatsbyImageWrapper = "carousel-module--gatsby-image-wrapper--KuER7";
export var left = "carousel-module--left--JthEf";
export var right = "carousel-module--right--y7ju9";
export var swiperContainer = "carousel-module--swiperContainer--Yv9oc";