import React, { useState, useEffect } from "react"
import * as R from "ramda"
import { graphql, Link, navigate } from "gatsby"
import { useAuth } from "../firebase"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import { BgImage } from "gbimage-bridge"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import LayoutController from "../components/layoutController"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import NavigateBefore from "@material-ui/icons/NavigateBefore"
import NavigateNext from "@material-ui/icons/NavigateNext"

import SocialShare from "../components/socialShare"
import Video from "../components/video"
import Carousel from "../components/carousel"
import Seo from "../components/seo"
import BackLink from "../components/backLink"
import PremiseLinks from "../components/premiseLinks"
import Card from "../components/card"

import * as styles from "./premisePage.module.scss"

const PremisePage = ({ data, pageContext }) => {
  const { user, firebase, loading } = useAuth()

  // UI texts
  const microCopyTexts = useMicroCopyFi
  const labelEnter = GetMicroCopy(microCopyTexts, "yleinenAstuSisään")
  const labelVirtualtour = GetMicroCopy(microCopyTexts, "kohdeVirtuaalikierros")
  const labelPremiseImage = GetMicroCopy(microCopyTexts, "kohdeKuva")
  const labelBlueprint = GetMicroCopy(microCopyTexts, "kohdePohja")
  const labelNextPremise = GetMicroCopy(microCopyTexts, "kohdeSeuraava")
  const labelPreviousPremise = GetMicroCopy(microCopyTexts, "kohdeEdellinen")
  const labelDesignersEye = GetMicroCopy(
    microCopyTexts,
    "kohdeSuunnittelijanSilmin"
  )
  const labelImageGallery = GetMicroCopy(microCopyTexts, "kohdeKuvagalleria")
  const labelInformation = GetMicroCopy(microCopyTexts, "kohdeTiedot")
  const labelStories = GetMicroCopy(microCopyTexts, "kohdeTarinat")
  const labelMakers = GetMicroCopy(microCopyTexts, "kohdeTekijät")
  const labelPremiseInfo = GetMicroCopy(microCopyTexts, "kohdeEsittely")
  const labelTechnicalInformation = GetMicroCopy(
    microCopyTexts,
    "kohdeTeknisetTiedot"
  )
  const labelWatchTour = GetMicroCopy(
    microCopyTexts,
    "KohdeKatsoSisustuskierros"
  )
  const labelToTour = GetMicroCopy(microCopyTexts, "kohdeKierrokselle")
  const labelWantToGiveFeedback = GetMicroCopy(
    microCopyTexts,
    "yleinenHaluatkoAntaaPalautetta"
  )
  const labelGiveFeedback = GetMicroCopy(
    microCopyTexts,
    "yleinenAnnaPalautetta"
  )
  const labelFeedbackText = GetMicroCopy(microCopyTexts, "kohdePalauteTeksti")
  const labelNext = GetMicroCopy(microCopyTexts, "yleinenSeuraavaksi")
  const labelArticlesFolder = GetMicroCopy(microCopyTexts, "polkuArtikkelit")
  // end UI texts

  const previousPage = R.path(["prev"], pageContext)
  const nextPage = R.path(["next"], pageContext)
  const nextNode = R.path(["next_node"], pageContext)
  const nextSecondNode = R.path(["next_second_node"], pageContext)
  const parentSlug = R.path(["parentSlug"], pageContext)
  const parentTitle = R.path(["parentTitle"], pageContext)
  const premisesSlug = R.path(["premisesSlug"], pageContext)
  const premisesTitle = R.path(["premisesTitle"], pageContext)

  const nextPremises = []
  if (nextNode !== "") {
    nextPremises.push(nextNode)
  }
  if (nextSecondNode !== "") {
    nextPremises.push(nextSecondNode)
  }

  const pageContent = R.path(["contentfulContent"], data)
  const language = R.path(["node_locale"], pageContent)

  const name = R.path(["name"], pageContent)
  const slug = R.path(["slug"], pageContent)
  const returnUrl = `${premisesSlug}${slug}`
  const category = R.path(["kategoria"], pageContent)
  const contentText = R.path(["contentText", "contentText"], pageContent)
  const contentTextShort = R.path(["contentTextShort"], pageContent)
  const kohdeEsittely = R.path(["kohdeEsittely", "kohdeEsittely"], pageContent)
  const tarinat = R.path(["tarinat", "tarinat"], pageContent)
  const tekijat = R.path(["tekijat", "tekijat"], pageContent)
  const teknisetTiedot = R.path(
    ["teknisetTiedot", "teknisetTiedot"],
    pageContent
  )
  const mainImage = R.path(["mainImage", "gatsbyImageData"], pageContent)
  const premiseImage = R.path(["premiseImage", "gatsbyImageData"], pageContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], pageContent)
  const pohjakuva = R.path(["pohjakuva", "gatsbyImageData"], pageContent)
  const video = R.path(["videoFile", "file", "url"], pageContent)
  const videoThumbnail = R.path(
    ["videotiedostonPysaytyskuva", "file", "url"],
    pageContent
  )
  const imageGallery = R.path(["imageSlider"], pageContent)
  const sisustuskierros = R.path(["sisustuskierros"], pageContent)
  const sisustusSlug = R.path(["slug"], sisustuskierros)
  const sisustusImage = R.path(
    ["mainImage", "gatsbyImageData"],
    sisustuskierros
  )
  const matterport = R.path(["matterportEsitys"], pageContent)
  const lainausteksti = R.path(["lainausteksti"], pageContent)
  const palautenosto = R.path(
    ["palautenosto", "childImageSharp", "gatsbyImageData"],
    data
  )
  const facebookUrl = R.path(["facebookUrl"], pageContent)
  const instagramUrl = R.path(["instagramUrl"], pageContent)
  const externalUrl = R.path(["externalUrl"], pageContent)

  let state = "matterport"
  if (!matterport) {
    state = "premiseImage"
  }

  const [choice, setChoice] = useState(state)

  let premiseImageButton =
    choice === "premiseImage" ? "choice" : "choice-notselected"
  let matterportbutton =
    choice === "matterport" ? "choice" : "choice-notselected"
  let pohjakuvabutton = choice === "pohjakuva" ? "choice" : "choice-notselected"
  let videobutton = choice === "video" ? "choice" : "choice-notselected"
  let imagegallerybutton =
    choice === "imageGallery" ? "choice" : "choice-notselected"

  // Colour options for bg image

  const {
    shape_lightGreen,
    shape_darkGreen,
    shape_yellow,
    shape_pink,
    shape_lightBlue,
  } = data

  const shapeLightGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightGreen
  )
  const shapeDarkGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_darkGreen
  )
  const shapeYellow = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_yellow
  )
  const shapePink = R.path(["childImageSharp", "gatsbyImageData"], shape_pink)
  const shapeLightBlue = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightBlue
  )

  // Gatsby background images seem to jump around during rendering. Static backgroundsize helps.
  // The following stuff helps to take care of this problem and defines two sizes for mobile/laptop.
  let shapeColor = ""
  let shapeSize = ""
  let shapeMinHeight = ""

  const colorItems = [
    "Sininen",
    "Vihreä",
    "Tummanvihreä",
    "Keltainen",
    "Pinkki",
  ]

  const color = colorItems[Math.floor(Math.random() * colorItems.length)]

  const mobileSizeDetected = useMediaQuery("(max-width:700px)")

  shapeMinHeight = mobileSizeDetected ? "190px" : "190px"

  if (color === "Vihreä") {
    shapeColor = shapeLightGreen
    shapeSize = mobileSizeDetected ? "190px 129px" : "190px 129px"
  }
  if (color === "Tummanvihreä") {
    shapeColor = shapeDarkGreen
    shapeSize = mobileSizeDetected ? "190px 129px" : "190px 129px"
  }
  if (color === "Keltainen") {
    shapeColor = shapeYellow
    shapeSize = mobileSizeDetected ? "193px 129px" : "193px 129px"
  }
  if (color === "Sininen") {
    shapeColor = shapeLightBlue
    shapeSize = mobileSizeDetected ? "191px 128px" : "191px 128px"
  }
  if (color === "Pinkki") {
    shapeColor = shapePink
    shapeSize = mobileSizeDetected ? "203px 135px" : "203px 135px"
  }

  useEffect(() => {
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF 'user' changes (from null to not null)
    // loading = false, user = null, lead user to login page.
    if (!loading && !user) {
      navigate("/login", { state: { returnUrl: returnUrl } })
    }
  }, [loading, user, returnUrl])


  // Hide code check from Naantali expo

  // useEffect(() => {
  //   async function checkRegisteredCode(user, returnUrl) {
  //     const response = await firebase.checkRegisteredCode({
  //       email: user.email
  //     })
  //     if (response.data._size == 0) {
  //       navigate("/code", { state: { returnUrl: returnUrl } })
  //     }
  //   }
  //   if (firebase && user && returnUrl.includes('naantali-2022')) {
  //     checkRegisteredCode(user, returnUrl)
  //   }
  // }, [firebase, user, returnUrl])

  return (
    <LayoutController language={language}>
    <div className="mt-10">
      <Seo
        seoTitle={`${name} | ${premisesTitle} | ${parentTitle}`}
        seoDesc={contentTextShort}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        <Box
          className="max-w-6xl"
          style={{ width: "100%", marginBottom: "70px" }}
        >
          <Box className={styles.titleContainer}>
            <Grid container>
              <Grid
                item
                sm={12}
                style={{ paddingLeft: "24px", marginBottom: "35px" }}
              >
                <BackLink label={premisesTitle} url={`${premisesSlug}`} />
              </Grid>

              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
              <Grid item xs={10} className={styles.center}>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={9}>
                    <h1 style={{ paddingRight: "20px" }}>{name}</h1>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={3}
                    style={{ display: "inline-flex", alignItems: "flex-end" }}
                  >
                    <SocialShare />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
            </Grid>
          </Box>

          <Box>
            {/** Select between matterport, floorplan images or video */}
            {matterport && (
              <Button
                onClick={() => setChoice("matterport")}
                className={matterportbutton}
                variant="contained"
                color="primary"
              >
                {labelVirtualtour}
              </Button>
            )}
            {!matterport && premiseImage && (
              <Button
                onClick={() => setChoice("premiseImage")}
                className={premiseImageButton}
                variant="contained"
                color="primary"
              >
                {labelPremiseImage}
              </Button>
            )}
            {pohjakuva && (
              <Button
                onClick={() => setChoice("pohjakuva")}
                className={pohjakuvabutton}
                variant="contained"
                color="primary"
              >
                {labelBlueprint}
              </Button>
            )}
            {video && (
              <Button
                onClick={() => setChoice("video")}
                className={videobutton}
                variant="contained"
                color="primary"
              >
                {labelDesignersEye}
              </Button>
            )}
            {imageGallery && (
              <Button
                onClick={() => setChoice("imageGallery")}
                className={imagegallerybutton}
                variant="contained"
                color="primary"
              >
                {labelImageGallery}
              </Button>
            )}
          </Box>

          <Box className={styles.mainImageContainer}>
            <Grid container>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {imageGallery && choice === "imageGallery" && (
                  <div id="image-gallery-prev">
                    <NavigateBefore style={{ fontSize: "48px" }} />
                  </div>
                )}
              </Grid>
              <Grid item xs={10} className={styles.center}>
                {!matterport && !premiseImage && choice !== "premiseImage" && (
                  <GatsbyImage
                    image={mainImage}
                    alt=""
                    style={{ maxHeight: "656px" }}
                  />
                )}

                {!matterport && premiseImage && choice === "premiseImage" && (
                  <GatsbyImage
                    alt=""
                    image={premiseImage}
                    style={{ maxHeight: "656px" }}
                  />
                )}

                {matterport && choice === "matterport" && (
                  <div className="iframecontainer">
                    <iframe
                      src={matterport}
                      frameBorder="0"
                      title="matterport"
                      allowFullScreen
                      allow="xr-spatial-tracking"
                    ></iframe>
                  </div>
                )}

                {pohjakuva && choice === "pohjakuva" && (
                  <BgImage
                    image={pohjakuva}
                    style={{ height: "656px", backgroundSize: "contain" }}
                  />
                )}

                {video && choice === "video" && (
                  <Video video={video} videoThumbnail={videoThumbnail} />
                )}

                {imageGallery && choice === "imageGallery" && (
                  <Carousel imageGallery={imageGallery} />
                )}

                {/** Next / prev premise navigation */}
                <div className={styles.carouselNavigation}>
                  {previousPage && (
                    <Link
                      className="blue"
                      to={`${premisesSlug}${previousPage}`}
                    >
                      <Button
                        variant="contained"
                        className="carousel-navigation--prev"
                      >
                        {labelPreviousPremise}
                      </Button>
                    </Link>
                  )}

                  {nextPage && (
                    <Link className="blue" to={`${premisesSlug}${nextPage}`}>
                      <Button
                        variant="contained"
                        className="carousel-navigation--next"
                      >
                        {labelNextPremise}
                      </Button>
                    </Link>
                  )}
                </div>

                <Box
                  style={{ marginTop: "20px" }}
                  className={styles.hashbuttoncontainer}
                >
                  <a href="#tiedot">
                    <Button
                      className={styles.hashbutton}
                      variant="contained"
                      color="primary"
                    >
                      {labelInformation}
                    </Button>
                  </a>
                  <a href="#tarinat">
                    <Button
                      className={styles.hashbutton}
                      variant="contained"
                      color="primary"
                    >
                      {labelStories}
                    </Button>
                  </a>
                  <a href="#tekijat">
                    <Button
                      className={styles.hashbutton}
                      variant="contained"
                      color="primary"
                    >
                      {labelMakers}
                    </Button>
                  </a>
                  <a href="#tekniset-tiedot">
                    <Button
                      className={styles.hashbutton}
                      variant="contained"
                      color="primary"
                    >
                      {labelTechnicalInformation}
                    </Button>
                  </a>
                </Box>
              </Grid>
              <Grid
                item
                xs={1}
                className={styles.right}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {imageGallery && choice === "imageGallery" && (
                  <div id="image-gallery-next">
                    <NavigateNext style={{ fontSize: "48px" }} />
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>

          <Grid container className={styles.ingressiContainer}>
            <Grid item xs={12} md={12} lg={7} style={{}}>
              <div id="tiedot" className="ingressi" style={{}}>
                <ReactMarkdown children={contentText} softBreak="br" />
              </div>
              <div id="tarinat" className={styles.tarinatContainer}>
                <ReactMarkdown children={tarinat} softBreak="br" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={5}
              className={styles.paragraphHolder}
            >
              <h4>{labelPremiseInfo}</h4>
              <div className={styles.kohdeEsittely}>
                <ReactMarkdown children={kohdeEsittely} softBreak="br" />
                {(instagramUrl || facebookUrl || externalUrl) && (
                  <PremiseLinks
                    instagram={instagramUrl}
                    facebook={facebookUrl}
                    external={externalUrl}
                  />
                )}
              </div>

              {sisustuskierros && (
                <div className={styles.sisustuskierrosnosto}>
                  <Grid
                    container
                    spacing={0}
                    className={styles.premiseliftcontainer}
                    style={{
                      height: "265px",
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        backgroundColor: "transparent",
                        width: "246px",
                        height: "265px",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          clipPath:
                            "polygon(50% 0%, 100% 34%, 100% 100%, 0 100%, 0% 34%)",
                        }}
                      >
                        <BgImage
                          image={sisustusImage}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={styles.premiselifttextareacontainer}
                    >
                      <div
                        className={styles.premiselifttextarea}
                        style={{
                          backgroundColor: "#fcf380",
                          marginTop: "0px",
                          height: "176px",
                          paddingTop: "40px",
                        }}
                      >
                        <p style={{ marginBottom: "15px" }}>{labelWatchTour}</p>
                        <Link
                          to={`/${labelArticlesFolder}/${sisustusSlug}`}
                          style={{}}
                          className="pink"
                        >
                          <Button variant="contained" color="primary">
                            {labelToTour}
                          </Button>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}

              {lainausteksti && (
                <BgImage
                  image={shapeColor}
                  style={{
                    minHeight: shapeMinHeight,
                    backgroundSize: shapeSize,
                    backgroundPositionX: "0px",
                  }}
                >
                  <h4 style={{ marginTop: "100px" }}>{lainausteksti}</h4>
                </BgImage>
              )}

              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "20px" }}
              >
                <Box
                  style={{
                    width: "100%",
                    minHeight: "300px",
                    textAlign: "center",
                  }}
                >
                  <BgImage
                    image={palautenosto}
                    style={{
                      padding: "30px",
                      position: "relative",
                      textAlign: "center",
                      width: "100%",
                      backgroundPositionY: "0px",
                      minWidth: "100%",
                      backgroundSize: "contain",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={styles.palautenostodiv}
                        style={{ maxWidth: "300px" }}
                      >
                        <h2 className="palautenosto">
                          {labelWantToGiveFeedback}
                        </h2>
                        <p>{labelFeedbackText}</p>
                      </div>
                      <Link
                        to={`https://www.lyyti.fi/reg/asuntomessut_palaute`}
                        className="pink"
                        style={{ position: "absolute", bottom: "-10px" }}
                      >
                        <Button variant="contained" color="primary">
                          {labelGiveFeedback}
                        </Button>
                      </Link>
                    </div>
                  </BgImage>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={styles.infoContainer}>
            <Grid
              item
              xs={12}
              md={3}
              style={{ backgroundColor: "#b4e9fc", padding: "20px" }}
            >
              <h2 id="tekijat" className="premisesubtitle">
                {labelMakers}
              </h2>
            </Grid>
            <Grid item xs={12} md={9} className={styles.paragraphHolder}>
              <div className={styles.flexList}>
                <ReactMarkdown children={tekijat} softBreak="br" />
              </div>
            </Grid>
          </Grid>

          <Grid container className={styles.infoContainer}>
            <Grid
              item
              xs={12}
              md={3}
              style={{ backgroundColor: "#fcf380", padding: "20px" }}
            >
              <h2 id="tekniset-tiedot" className="premisesubtitle">
                {labelTechnicalInformation}
              </h2>
            </Grid>
            <Grid item xs={12} md={9} className={styles.paragraphHolder}>
              <div className={styles.flexList}>
                <ReactMarkdown children={teknisetTiedot} softBreak="br" />
              </div>
            </Grid>
          </Grid>

          {R.path(["slug"], R.head(nextPremises)) && (
            <Grid
              container
              spacing={3}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <Grid item xs={12}>
                <h2 className="premisesubtitle">{labelNext}</h2>
              </Grid>
              {nextPremises.map(item => {
                let kat = R.path(["kategoria"], item)
                const action = labelEnter
                const folder = `kohteet`

                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ width: "100%", marginBottom: "20px" }}
                    key={R.path(["slug"], item)}
                  >
                    <Card
                      image={R.path(["mainImage", "gatsbyImageData"], item)}
                      title={R.path(["title"], item) || R.path(["name"], item)}
                      url={`${premisesSlug}${R.path(["slug"], item)}`}
                      text={R.path(["contentTextShort"], item)}
                      language="fi"
                    />
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Box>
      </Box>
    </div>
    </LayoutController>
  )
}

export default PremisePage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query premisePageQuery($slug: String) {
    contentfulContent: contentfulKohdesivu(slug: { eq: $slug }) {
      id
      name
      node_locale
      kategoria
      slug
      mainImage {
        id
        file {
          url
        }
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      pohjakuva {
        id
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      premiseImage {
        id
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      matterportEsitys
      lainausteksti
      videoFile {
        file {
          url
        }
      }
      videotiedostonPysaytyskuva {
        id
        file {
          url
        }
      }
      imageSlider {
        title
        text {
          text
        }
        image {
          gatsbyImageData(
            width: 1200
            quality: 60
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      tarinat {
        tarinat
      }
      kohdeEsittely {
        kohdeEsittely
      }
      tekijat {
        tekijat
      }
      teknisetTiedot {
        teknisetTiedot
      }
      contentText {
        contentText
      }
      contentTextShort
      sisustuskierros {
        ... on Node {
          ... on ContentfulArtikkelisivu {
            __typename
            slug
            mainImage {
              id
              file {
                url
              }
              gatsbyImageData(quality: 60, layout: CONSTRAINED)
            }
          }
        }
      }
      facebookUrl
      instagramUrl
      externalUrl
    }
    shape_lightGreen: file(relativePath: { eq: "shape_lightGreen.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_darkGreen: file(relativePath: { eq: "shape_darkGreen.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_yellow: file(relativePath: { eq: "shape_yellow.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_pink: file(relativePath: { eq: "shape_pink.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_lightBlue: file(relativePath: { eq: "shape_lightBlue.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    palautenosto: file(relativePath: { eq: "Group 256.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 400
          layout: CONSTRAINED
        )
      }
    }
  }
`
