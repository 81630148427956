import React from "react"
import ReactPlayer from "react-player"
import Box from "@material-ui/core/Box"
import * as styles from "./video.module.scss"

const Video = ({ video, videoThumbnail }) => {
  return (
    <Box className={styles.video}>
      <ReactPlayer
        controls
        height="100%"
        width="100%"
        url={video}
        light={videoThumbnail ? videoThumbnail : null}
      />
    </Box>
  )
}

export default Video
