import React from "react"
import PropTypes from "prop-types"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import PublicIcon from "@material-ui/icons/Public"

const PremiseLinks = ({ instagram, facebook, external }) => {
  return (
    <div>
      {facebook && (
        <a className="text-[#4267B2] mr-2" href={facebook}>
          <FacebookIcon />
        </a>
      )}
      {instagram && (
        <a
          className="text-[#C13584] hover:text-[#C13584] hover:opacity-70 mr-2"
          href={instagram}
        >
          <InstagramIcon />
        </a>
      )}
      {external && (
        <a className="text-text" href={external}>
          <PublicIcon />
        </a>
      )}
    </div>
  )
}

export default PremiseLinks

PremiseLinks.propTypes = {
  instagram: PropTypes.string,
  facebook: PropTypes.string,
  external: PropTypes.string,
}
