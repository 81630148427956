import React, { useEffect } from "react"
import Swiper, { Autoplay, Navigation } from "swiper"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "@material-ui/core/Button"
import "swiper/css/bundle"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import * as styles from "./carousel.module.scss"

import Grid from "@material-ui/core/Grid"

import NavigateBefore from "@material-ui/icons/NavigateBefore"
import NavigateNext from "@material-ui/icons/NavigateNext"

import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"

Swiper.use([Autoplay, Navigation])

const Carousel = ({ imageGallery }) => {
  useEffect(() => {
    const carousel = new Swiper(".carousel", {
      slidesPerView: "auto",
      grabCursor: false,
      freeMode: true,
      loop: true,
      centeredSlides: true,
      initialSlide: 2,
      spaceBetween: 24,
      maxWidth: 1200,
      autoplay: {
        delay: 7000,
      },
      navigation: {
        nextEl: "#image-gallery-next",
        prevEl: "#image-gallery-prev",
      },
    })
  }, [])
  // UI texts
  const microCopyTexts = useMicroCopyFi
  const labelSeeMore = GetMicroCopy(microCopyTexts, "yleinenKatsoLisää")
  // end UI texts
  const [open, setOpen] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [maxWidth, setMaxWidth] = React.useState("lg")

  const handleClickOpen = index => {
    setCurrentIndex(index)
    setOpen(true)
  }

  const handleChangePicture = index => {
    setCurrentIndex(index)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <section className="carousel-section">
      <Dialog
        className={styles.carouselDialog}
        key={`dialog`}
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <div
          className={styles.carouselDialogContainer}
          style={{ width: "100%" }}
        >
          <div className={styles.carouselCloseButton}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <Grid container>
            <Grid
              item
              xs={1}
              className={styles.left}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{ cursor: "pointer", color: "#69d2fa" }}
                onClick={() => {
                  const indprev =
                    currentIndex === 0
                      ? imageGallery.length - 1
                      : currentIndex - 1
                  return handleChangePicture(indprev)
                }}
              >
                <NavigateBefore style={{ fontSize: "48px" }} />
              </div>
            </Grid>
            <Grid item xs={10} className={styles.center}>
              <figure className={styles.carouselDialogImage}>
                <GatsbyImage
                  alt=""
                  image={{
                    ...imageGallery[currentIndex].image.gatsbyImageData,
                  }}
                  imgStyle={{ objectFit: "cover" }}
                  style={{
                    width: "100%",
                    height: "auto",
                    margin: "0 auto 12px auto",
                  }}
                />

                {imageGallery[currentIndex].title && (
                  <figcaption>
                    <h4 style={{ marginBottom: "12px" }}>
                      {imageGallery[currentIndex].title}
                    </h4>
                    {imageGallery[currentIndex].text && (
                      <p>{imageGallery[currentIndex].text.text}</p>
                    )}
                  </figcaption>
                )}
              </figure>
            </Grid>
            <Grid
              item
              xs={1}
              className={styles.right}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{ cursor: "pointer", color: "#69d2fa" }}
                onClick={() => {
                  const indnext =
                    currentIndex === imageGallery.length - 1
                      ? 0
                      : currentIndex + 1
                  handleChangePicture(indnext)
                }}
              >
                <NavigateNext style={{ fontSize: "48px" }} />
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <div>
        <div
          className="carousel swiper-container"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
            overflow: "hidden",
            listStyle: "none",
            padding: "0",
            zIndex: "1",
          }}
        >
          <div className="swiper-wrapper">
            {imageGallery.map((item, index) => {
              return (
                <div
                  key={`swiper-slide-${index}`}
                  className={`${styles.carouselSlide} swiper-slide`}
                >
                  <GatsbyImage
                    style={{
                      aspectRatio: "10/8",
                    }}
                    alt=""
                    image={{
                      ...item.image.gatsbyImageData,
                      aspectRatio: 8 / 10,
                    }}
                    key={`slide-image-${index}`}
                  />

                  <div className={styles.carouselSlideButton}>
                    <span className="blue">
                      <Button
                        variant="contained"
                        onClick={() => handleClickOpen(index)}
                      >
                        {labelSeeMore}
                      </Button>
                    </span>
                  </div>

                  <div className={styles.carouselOverlay}></div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Carousel
